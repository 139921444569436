import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Seo from '../../components/seo'
import SecondaryHeading from '../../components/SecondaryHeading'
import SectionDoubleColumn from '../../components/SectionDoubleColumn'
import SideCol from '../../components/SectionDoubleColumn/SideCol'
import ReactMarkdown from 'react-markdown'
import Image from '../../components/image'
import Fade from 'react-reveal/Fade'
import generateLink from '../../utils/generateLink'

const Mission = ({ langKey, location }) => {
  const { site } = useStaticQuery(
    graphql` query {
      site {
        siteMetadata {
          pagesData {
            company {
              subPage {
                mission {
                  title {
                    en
                    de
                    it
                  }
                  subtitle {
                    en
                    de
                    it
                  }
                  firstParagtph {
                    en
                    de
                    it
                  }
                }
              }
              sideSection {
                firstBlock {
                  de
                  en
                  it
                }
                links {
                  text {
                    en
                    it
                    de
                  }
                  path
                }
              }
            }
          }
        }
      }
    }
      `
  )

  const {
    sideSection,
    subPage: {
      mission
    }
  } = site.siteMetadata.pagesData.company

  const mainCol = (
    <div>
      <div>
        <h2 className='text-3xl underline font-bold mb-16'>{mission.subtitle[langKey]}</h2>
        <Fade right>
          {/* <div>
            <Image imgRef='consulting' />
          </div> */}
          <div className='my-10'>
            <ReactMarkdown source={mission.firstParagtph[langKey]} />
          </div>
          <div>
            <Image imgRef='target' />
          </div>
        </Fade>
      </div>
    </div>
  )

  const linksForSide = sideSection.links.map((el) => ({ path: generateLink(langKey, '/company/' + el.path), text: el.text[langKey], selected: el.path === 'mission' }))
  const sideCol = <SideCol links={linksForSide} textBlock={sideSection.firstBlock[langKey]} />

  return (
    <>
      <Seo title={mission.title[langKey]} urlPath={location.pathname} />
      <SecondaryHeading title={mission.title[langKey]} />
      <SectionDoubleColumn mainCol={mainCol} sideCol={sideCol} mainVisibleInSmallScreen />
    </>
  )
}

export default Mission
